import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    @font-face {
        font-family: 'Articulat CF';
        src: url('/assets/fonts/ArticulatCF-DemiBold.woff2') format('woff2'),
            url('/assets/fonts/ArticulatCF-DemiBold.woff') format('woff');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Articulat CF';
        src: url('/assets/fonts/ArticulatCF-Medium.woff2') format('woff2'),
            url('/assets/fonts/ArticulatCF-Medium.woff') format('woff');
        font-weight: 500;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    body {
        font-family: Articulat, helvetica, arial, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        overflow: hidden;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    #root {
        display: flex;
        font-size: 14px;
        color: #ccc;
        background-color: #000;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #171717;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #171717;
    }
`;
