import styled from 'styled-components';
import React, { useState, useCallback } from 'react';
import { formatToSeconds } from '../App';

const Style = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    background-color: #111;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .loader-spin {
        animation-name: spin;
        animation-duration: 5000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .content {
        padding-top: 20px;
    }

    .exportTitle {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 12px;
            font-weight: 600;
            color: #737373;
        }
    }

    .line {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
    }

    .btn {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        gap: 6px;
        border: 0;
        height: 36px;
        width: 186px;
        cursor: pointer;
        border-radius: 8px;

        color: #000;
        font-size: 13px;
        font-weight: 600;
        transition: all 0.2s ease 0s;
        background-color: #00dae8;

        &[disabled] {
            opacity: 0.7;
            cursor: initial;
        }

        &:not([disabled]):hover {
            opacity: 0.7;
        }

        &.btn-white {
            color: #000;
            background-color: #fff;
        }

        &.light {
            color: #fff;
            border: 2px solid #545454;
            background-color: transparent;
        }
    }
`;

export default function Header({
    player,
    waveform,
    newSub,
    undoSubs,
    clearSubs,
    language,
    subtitle,
    setLoading,
    formatSub,
    setSubtitle,
    setProcessing,
    notify,
    hasChangedOnLyrics,
}) {
    const [loadingCloseAndSave, setLoadingCloseAndSave] = useState(false);
    const [loadingRestore, setLoadingRestore] = useState(false);

    const restoreLyrics = useCallback(() => {
        // setLoadingRestore(true);
        window.parent.postMessage(
            {
                type: 'lyrics-restore',
                message: '',
            },
            '*',
        );
    }, []);

    const closeEditorAndSave = useCallback(() => {
        // console.log('closeEditorAndSave init', subtitle);
        setLoadingCloseAndSave(true);

        if (!hasChangedOnLyrics) {
            window.parent.postMessage(
                {
                    type: 'lyrics-return-without-save',
                },
                '*',
            );
            return;
        }

        const list = [];
        let line = 1;

        // normalize items
        subtitle
            .filter((item) => item.text !== 'lock-premium-item')
            .forEach((item, index) => {
                // define new line
                if (index !== 0 && item.text === '<SOL>') {
                    // add item <EOL> (End Of Line)
                    const lastItem = subtitle[index - 1];
                    const itemEOL = {
                        id: index,
                        line_id: line,
                        start: formatToSeconds(lastItem.end),
                        end: formatToSeconds(lastItem.end),
                        text: '<EOL>',
                    };
                    list.push(itemEOL);

                    line += 1;
                }

                const i = {
                    id: index,
                    line_id: line,
                    start: formatToSeconds(item.start),
                    end: formatToSeconds(item.end),
                    text: item.text,
                };
                list.push(i);
            });

        window.parent.postMessage(
            {
                type: 'lyrics-subtitle',
                message: list,
            },
            '*',
        );
    }, [subtitle, hasChangedOnLyrics]);

    // const listenerPostMessage = useCallback((e) => {
    //     if (e.data.type === 'lyrics-subtitle-success') {
    //         window.history.back();
    //     }
    // }, []);

    // useEffect(() => {
    //     window.addEventListener('message', listenerPostMessage);
    //     return () => {
    //         window.removeEventListener('message', listenerPostMessage);
    //     };
    // }, [listenerPostMessage]);

    return (
        <Style className="tool">
            <div className="content">
                <div className="operate">
                    <div className="line">
                        <button
                            className="btn"
                            disabled={loadingCloseAndSave || loadingRestore}
                            onClick={closeEditorAndSave}
                        >
                            {loadingCloseAndSave ? (
                                <img src="icons/ic-loader.svg" width={16} alt="file" className="loader-spin" />
                            ) : (
                                <img src="icons/ic-check.svg" width={16} alt="file" />
                            )}
                            <span>{loadingCloseAndSave ? 'Saving...' : 'Save'}</span>
                        </button>
                    </div>

                    <div className="line">
                        <button
                            disabled={loadingCloseAndSave || loadingRestore}
                            className="btn btn-white"
                            onClick={undoSubs}
                        >
                            <img src="icons/ic-undo.svg" width={16} alt="file" />
                            <span>Undo</span>
                        </button>
                    </div>
                </div>

                <div className="exportTitle">
                    <p>Restore AI Transcription</p>
                </div>

                <div className="export">
                    <div className="line">
                        <button
                            disabled={loadingCloseAndSave || loadingRestore}
                            onClick={restoreLyrics}
                            className="btn light"
                        >
                            {loadingRestore ? (
                                <img src="icons/ic-loader-white.svg" width={16} alt="file" className="loader-spin" />
                            ) : (
                                <img src="icons/ic-refresh-back.svg" width={16} alt="file" />
                            )}
                            <span>{loadingRestore ? 'Loading...' : 'Restore'}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Style>
    );
}
