import styled from 'styled-components';
import DT from 'duration-time-conversion';
import React, { useState, useEffect, useCallback, createRef, memo } from 'react';
import WFPlayer from 'wfplayer';
import clamp from 'lodash/clamp';
import throttle from 'lodash/throttle';
import Timeline from './Timeline';
import Metronome from './Metronome';

const Style = styled.div`
    position: relative;
    display: flex;
    align-items: right;
    flex-direction: column;

    .progress {
        position: absolute;
        left: 0;
        right: 0;
        top: -12px;
        z-index: 11;
        width: 100%;
        height: 12px;
        user-select: none;
        border-top: 1px solid rgb(255 255 255 / 20%);
        background-color: rgb(0 0 0 / 50%);

        .bar {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0%;
            height: 100%;
            display: inline-block;
            background-color: #00dae8;
            overflow: hidden;

            .handle {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 10px;
                cursor: ew-resize;
                background-color: #ffffff;
            }
        }

        .subtitle {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;

            span {
                position: absolute;
                top: 0;
                bottom: 0;
                height: 100%;
                background-color: rgb(255 255 255 / 20%);
            }
        }
    }

    .durationControls {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: 0;
        top: -148px;
        z-index: 999999;
        font-size: 18px;
        color: rgb(255 255 255 / 75%);
        text-align: center;
        width: 30%;

        @media screen and (max-height: 700px) {
            top: -118px;
        }
    }

    .durationContainer {
        display: inline-flex;
        flex-direction: row;
        align-items: baseline;
        gap: 4px;
    }

    .btn {
        opacity: 0.85;
        margin: 10px;
        height: 32px;
        width: 100px;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        font-size: 13px;
        outline: 1.5px solid #666666;
        background-color: transparent;
        border: 0px solid rgb(255 255 255 / 20%);

        &:hover {
            opacity: 1;
        }
    }

    .btn-play {
        opacity: 0.85;
        height: 54px;
        width: 54px;
        border-radius: 100px;
        color: #000;
        cursor: pointer;
        background-color: #fff;
        outline: none;
        border: none;
        &:hover {
            opacity: 1;
        }

        img {
            padding: 5px 0px 0px 0px;
        }
    }

    .btn-speed {
        opacity: 0.85;
        height: 38px;
        width: 38px;
        border-radius: 100px;
        color: #000;
        cursor: pointer;
        background-color: #3b3b3b;
        outline: none;
        border: none;
        &:hover {
            opacity: 1;
        }

        img {
            padding: 5px 0px 0px 0px;
        }
    }

    .duration {
        position: absolute;
        left: 0;
        right: 0;
        top: -70px;
        z-index: 12;
        width: 100%;
        font-size: 18px;
        color: rgb(255 255 255 / 75%);
        text-shadow: 0 1px 2px rgb(0 0 0 / 75%);
        text-align: center;
        user-select: none;
        pointer-events: none;

        @media screen and (max-height: 700px) {
            top: -40px;
        }
    }

    .waveform {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        z-index: 1;
        user-select: none;
        pointer-events: none;
    }

    .grab {
        position: relative;
        z-index: 11;
        cursor: grab;
        height: 13%;
        user-select: none;
        background-color: #ffffff0a;
        // border-top: 1px solid rgb(33 150 243 / 30%);
        // border-bottom: 1px solid rgb(33 150 243 / 30%);

        &.grabbing {
            cursor: grabbing;
        }
    }
`;

const Waveform = memo(
    ({ player, setWaveform, setRender, src }) => {
        const $waveform = createRef();

        useEffect(() => {
            [...WFPlayer.instances].forEach((item) => item.destroy());

            const waveform = new WFPlayer({
                scrollable: true,
                useWorker: false,
                duration: 2,
                padding: 1,
                wave: true,
                pixelRatio: 2,
                container: $waveform.current,
                mediaElement: player,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                waveColor: 'rgba(255, 255, 255, 0.2)',
                progressColor: 'rgba(0, 150, 136, 0.5)',
                gridColor: 'rgba(255, 255, 255, 0.05)',
                rulerColor: '#fff',
                paddingColor: 'rgba(0, 0, 0, 0)',
                cursorColor: '#0AFFA7',
            });

            setWaveform(waveform);
            waveform.on('update', setRender);
            waveform.load(src);
        }, [player, $waveform, setWaveform, setRender, src]);

        return <div className="waveform" ref={$waveform} />;
    },
    () => true,
);

const Grab = (props) => {
    const [grabStartX, setGrabStartX] = useState(0);
    const [grabStartTime, setGrabStartTime] = useState(0);
    const [grabbing, setGrabbing] = useState(false);

    const onGrabDown = useCallback(
        (event) => {
            if (event.button !== 0) return;
            setGrabStartX(event.pageX);
            setGrabStartTime(props.player.currentTime);
            setGrabbing(true);
        },
        [props.player],
    );

    const onGrabUp = () => {
        setGrabStartX(0);
        setGrabStartTime(0);
        setGrabbing(false);
    };

    const onGrabMove = useCallback(
        (event) => {
            if (grabbing && props.player && props.waveform) {
                const currentTime = clamp(
                    grabStartTime - ((event.pageX - grabStartX) / document.body.clientWidth) * 10,
                    0,
                    props.player.duration,
                );
                props.player.currentTime = currentTime;
                props.waveform.seek(currentTime);
            }
        },
        [grabbing, props.player, props.waveform, grabStartX, grabStartTime],
    );

    useEffect(() => {
        document.addEventListener('mouseup', onGrabUp);
        return () => document.removeEventListener('mouseup', onGrabUp);
    }, []);

    return (
        <div className={`grab ${grabbing ? 'grabbing' : ''}`} onMouseDown={onGrabDown} onMouseMove={onGrabMove}></div>
    );
};

const Progress = (props) => {
    const [grabbing, setGrabbing] = useState(false);

    const onProgressClick = useCallback(
        (event) => {
            if (event.button !== 0) return;
            const currentTime = (event.pageX / document.body.clientWidth) * props.player.duration;
            props.player.currentTime = currentTime;
            props.waveform.seek(currentTime);
        },
        [props],
    );

    const onGrabDown = useCallback(
        (event) => {
            if (event.button !== 0) return;
            setGrabbing(true);
        },
        [setGrabbing],
    );

    const onGrabMove = useCallback(
        (event) => {
            if (grabbing) {
                const currentTime = (event.pageX / document.body.clientWidth) * props.player.duration;
                props.player.currentTime = currentTime;
            }
        },
        [grabbing, props.player],
    );

    const onDocumentMouseUp = useCallback(() => {
        if (grabbing) {
            setGrabbing(false);
            props.waveform.seek(props.player.currentTime);
        }
    }, [grabbing, props.waveform, props.player.currentTime]);

    useEffect(() => {
        document.addEventListener('mouseup', onDocumentMouseUp);
        document.addEventListener('mousemove', onGrabMove);
        return () => {
            document.removeEventListener('mouseup', onDocumentMouseUp);
            document.removeEventListener('mousemove', onGrabMove);
        };
    }, [onDocumentMouseUp, onGrabMove]);

    return (
        <div className="progress" onClick={onProgressClick}>
            <div className="bar" style={{ width: `${(props.currentTime / props.player.duration) * 100}%` }}>
                <div className="handle" onMouseDown={onGrabDown}></div>
            </div>
            <div className="subtitle">
                {props.subtitle.length <= 600
                    ? props.subtitle.map((item, index) => {
                          const { duration } = props.player;
                          return (
                              <span
                                  key={index}
                                  className="item"
                                  style={{
                                      left: `${(item.startTime / duration) * 100}%`,
                                      width: `${(item.duration / duration) * 100}%`,
                                  }}
                              ></span>
                          );
                      })
                    : null}
            </div>
        </div>
    );
};

const Duration = (props) => {
    const getDuration = useCallback((time) => {
        time = time === Infinity ? 0 : time;
        return DT.d2t(time).split('.')[0];
    }, []);

    return (
        <div className="duration">
            <span style={{ fontSize: 14 }}>
                {getDuration(props.currentTime)} • {getDuration(props.player.duration || 0)}
            </span>
            {/* <span style={{ marginLeft: 6 }}>
                - Speed: {props.player.playbackRate.toFixed(2)}x
            </span> */}
        </div>
    );
};

const Controls = ({ player }) => {
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const increase = useCallback(() => {
        setPlaybackRate(player.playbackRate + 0.25);
        player.playbackRate += 0.25;
    }, [player]);
    const decrease = useCallback(() => {
        if (player.playbackRate === 0.25) {
            return;
        }
        setPlaybackRate(player.playbackRate - 0.25);
        player.playbackRate -= 0.25;
    }, [player]);
    return (
        <div className="durationControls">
            <div className="durationContainer">
                <button className="btn-speed" onClick={decrease}>
                    <img src="icons/ic-slow.svg" width={22} height={18} alt="file" />
                </button>
                <div className="playpause">
                    {player.paused ? (
                        <button
                            className="btn-play"
                            onClick={() => {
                                player.play();
                            }}
                        >
                            <div style={{ marginLeft: 2 }}>
                                <img src="icons/ic-play.svg" height={22} width={16} alt="file" />
                            </div>
                        </button>
                    ) : (
                        <button
                            className="btn-play"
                            onClick={() => {
                                player.pause();
                            }}
                        >
                            <img src="icons/ic-pause.svg" height={22} width={16} alt="file" />
                        </button>
                    )}
                    <p style={{ fontSize: 10, color: '#737373' }}>Speed {playbackRate.toFixed(2)}x</p>
                </div>
                <button className="btn-speed" onClick={increase}>
                    {' '}
                    <img src="icons/ic-fast.svg" width={22} height={19} alt="file" />
                </button>
            </div>
        </div>
    );
};

export default function Footer(props) {
    const $footer = createRef();
    const [render, setRender] = useState({
        padding: 2,
        duration: 10,
        gridGap: 10,
        gridNum: 110,
        beginTime: -5,
    });

    const onWheel = useCallback(
        (event) => {
            if (
                !props.player ||
                !props.waveform ||
                props.player.playing ||
                !$footer.current ||
                !$footer.current.contains(event.target)
            ) {
                return;
            }

            const deltaY = Math.sign(event.deltaY) / 5;
            const currentTime = clamp(props.player.currentTime + deltaY, 0, props.player.duration);
            props.player.currentTime = currentTime;
            props.waveform.seek(currentTime);
        },
        [props.waveform, props.player, $footer],
    );

    useEffect(() => {
        const onWheelThrottle = throttle(onWheel, 100);
        window.addEventListener('wheel', onWheelThrottle);
        return () => window.removeEventListener('wheel', onWheelThrottle);
    }, [onWheel]);

    return (
        <Style className="footer" ref={$footer}>
            {props.player ? (
                <React.Fragment>
                    <Controls {...props} />
                    <Progress {...props} />

                    <Duration {...props} />

                    <Waveform {...props} setRender={setRender} />
                    <Grab {...props} render={render} />
                    <Metronome {...props} render={render} />
                    <Timeline {...props} render={render} />
                </React.Fragment>
            ) : null}
        </Style>
    );
}
