import styled from 'styled-components';
import React, { useCallback } from 'react';

const Style = styled.div`
    .button {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 0;
        padding: 12px;
        gap: 12px;
        border-radius: 10px;
        background: black;
        cursor: pointer;
        min-width: 185px;

        z-index: 1;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translatey(-50%);

        &:hover {
            opacity: 0.9;
        }
    }

    .text {
        font-size: 12px;
        color: #666666;
        margin: 0;
    }
`;

export default function LockPremium({ small = false }) {
    const showModalGetPremium = useCallback(() => {
        window.parent.postMessage(
            {
                type: 'show-modal-get-premium',
                message: '',
            },
            '*',
        );
    }, []);

    return (
        <Style className="content">
            <button type="button" className="button" onClick={showModalGetPremium}>
                <img src="icons/ic-lock.svg" width={24} alt="file" className="icon" />
                {!small && <p className="text">Unlock unlimited AI Lyrics</p>}
            </button>
        </Style>
    );
}
