import styled from 'styled-components';
import React, { useState, useCallback, useEffect } from 'react';
import { Table } from 'react-virtualized';
import unescape from 'lodash/unescape';
import debounce from 'lodash/debounce';
import LockPremium from './LockPremium';

const Style = styled.div`
    top: 4px;
    position: relative;
    background-color: transparent;

    .ReactVirtualized__Table {
        .ReactVirtualized__Table__Grid {
            outline: none;
        }

        .ReactVirtualized__Table__row {
            overflow: initial !important;

            .header_line {
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 10px 0 20px;
                justify-content: space-between;

                &:hover {
                    .header_line_button {
                        opacity: 1;
                    }
                }

                .header_line_title {
                    color: #737373;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                }

                .header_line_button {
                    border: 0;
                    opacity: 0;
                    cursor: pointer;
                    background: none;
                    transition: opacity 0.2s ease;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            .item {
                height: 100%;
                padding: 5px;
                margin-left: 10px;
                position: relative;

                &:hover {
                    .button_new_line {
                        display: block;
                    }
                }

                .button_new_line {
                    top: -7px;
                    left: 0px;
                    margin: 0;
                    border: 0;
                    height: 23px;
                    opacity: 0.9;
                    padding: 0 10px;
                    display: none;
                    cursor: pointer;
                    position: absolute;
                    border-radius: 100px;

                    color: #000000;
                    font-size: 10px;
                    font-weight: 600;
                    background-color: #00dae8;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .textarea {
                    cursor: pointer;
                    border: none;
                    width: 100%;
                    height: 100%;
                    padding: 14px;
                    text-align: center;
                    overflow: hidden;

                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;

                    background-color: #171717;
                    border-radius: 100px;
                    transition: all 0.2s ease;
                    resize: none;
                    outline: none;

                    &.highlight {
                        background-color: #333;
                        color: #fff;
                    }

                    //&.illegal {
                    //    background-color: rgb(123 29 0);
                    //    border: 1px solid rgba(255, 255, 255, 0.3);
                    //}
                }
            }
        }
    }
`;

export default function Subtitles({ currentIndex, subtitle, checkSub, player, updateSub, addSub, removeSub }) {
    const [height, setHeight] = useState(100);

    const resize = useCallback(() => {
        setHeight(document.body.clientHeight - 170);
    }, [setHeight]);

    useEffect(() => {
        resize();
        if (!resize.init) {
            resize.init = true;
            const debounceResize = debounce(resize, 500);
            window.addEventListener('resize', debounceResize);
        }
    }, [resize]);

    const getRowHeight = useCallback(
        ({ index }) => {
            const item = subtitle[index];
            if (item.newLine) {
                return 30;
            } else {
                return 54;
            }
        },
        [subtitle],
    );

    const addNewLine = useCallback(
        ({ index, rowData }) => {
            // console.log('addNewLine', rowData);
            const item = {
                start: rowData.start,
                end: rowData.start,
                newLine: true,
                text: '<SOL>',
            };
            addSub(index, item);
        },
        [addSub],
    );

    const removeLine = useCallback(
        ({ rowData }) => {
            // console.log('removeLine', rowData);
            removeSub(rowData);
        },
        [removeSub],
    );

    return (
        <Style className="subtitles">
            <Table
                headerHeight={0}
                width={240}
                height={document.body.clientHeight - 320}
                // rowHeight={54}
                rowHeight={getRowHeight}
                scrollToIndex={currentIndex}
                rowCount={subtitle.length}
                rowGetter={({ index }) => subtitle[index]}
                headerRowRenderer={() => null}
                rowRenderer={(props) => {
                    if (props.rowData.text === 'lock-premium-item') {
                        return (
                            <div key={props.key} className={props.className} style={props.style}>
                                <LockPremium small />
                            </div>
                        );
                    }

                    if (props.rowData.newLine) {
                        return (
                            <div key={props.key} className={props.className} style={props.style}>
                                <div className="header_line">
                                    <p className="header_line_title">Line break</p>

                                    <button
                                        type="button"
                                        className="header_line_button"
                                        onClick={() => removeLine(props)}
                                    >
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_5420_68807)">
                                                <path
                                                    d="M2 4.00001H3.33333M3.33333 4.00001H14M3.33333 4.00001V13.3333C3.33333 13.687 3.47381 14.0261 3.72386 14.2762C3.97391 14.5262 4.31304 14.6667 4.66667 14.6667H11.3333C11.687 14.6667 12.0261 14.5262 12.2761 14.2762C12.5262 14.0261 12.6667 13.687 12.6667 13.3333V4.00001H3.33333ZM5.33333 4.00001V2.66668C5.33333 2.31305 5.47381 1.97392 5.72386 1.72387C5.97391 1.47382 6.31304 1.33334 6.66667 1.33334H9.33333C9.68696 1.33334 10.0261 1.47382 10.2761 1.72387C10.5262 1.97392 10.6667 2.31305 10.6667 2.66668V4.00001"
                                                    stroke="#737373"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_5420_68807">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div
                            key={props.key}
                            className={props.className}
                            style={props.style}
                            onClick={() => {
                                if (player) {
                                    player.pause();
                                    if (player.duration >= props.rowData.startTime) {
                                        player.currentTime = props.rowData.startTime + 0.001;
                                    }
                                }
                            }}
                        >
                            <div className="item">
                                <div
                                    maxLength={300}
                                    spellCheck={false}
                                    className={[
                                        'textarea',
                                        currentIndex === props.index ? 'highlight' : '',
                                        checkSub(props.rowData) ? 'illegal' : '',
                                    ]
                                        .join(' ')
                                        .trim()}
                                >
                                    {unescape(props.rowData.text)}
                                </div>
                                <button type="button" onClick={() => addNewLine(props)} className="button_new_line">
                                    + New line
                                </button>
                            </div>
                        </div>
                    );
                }}
            ></Table>
        </Style>
    );
}
