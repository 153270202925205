import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import DT from 'duration-time-conversion';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import Tool from './components/Tool';
import Subtitles from './components/Subtitles';
import Player from './components/Player';
import Footer from './components/Footer';
import Loading from './components/Loading';
import ProgressBar from './components/ProgressBar';
import { getKeyCode } from './utils';
import Sub from './libs/Sub';

function formatTimeToTimeline(seconds) {
    let hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    let hourString = hours.toString().padStart(2, '0');
    let minuteString = minutes.toString().padStart(2, '0');
    let secondString = seconds.toFixed(3).toString().padStart(6, '0');

    return `${hourString}:${minuteString}:${secondString}`;
}

export function formatToSeconds(hms) {
    const a = hms.split(':');
    const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    return seconds;
}

const Style = styled.div`
    height: 100%;
    width: 100%;

    @keyframes loading-roller {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .error_template {
        color: #fff;
        display: flex;
        height: 80vh;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .error_template_title {
            font-size: 24px;
            font-weight: 600;
        }

        .error_template_description {
            color: #737373;
            margin-top: 0px;
            font-size: 16px;
            text-align: center;
        }
    }

    .loading_default {
        width: 64px;
        height: 80vh;
        display: flex;
        margin: 0 auto;
        position: relative;
        align-items: center;

        div {
            animation: loading-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 32px 32px;
        }

        div::after {
            content: ' ';
            display: block;
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #00dae8;
            margin: -3px 0 0 -3px;
        }

        div:nth-child(1) {
            animation-delay: -0.036s;
        }

        div:nth-child(1)::after {
            top: 50px;
            left: 50px;
        }

        div:nth-child(2) {
            animation-delay: -0.072s;
        }

        div:nth-child(2)::after {
            top: 54px;
            left: 45px;
        }

        div:nth-child(3) {
            animation-delay: -0.108s;
        }

        div:nth-child(3)::after {
            top: 57px;
            left: 39px;
        }

        div:nth-child(4) {
            animation-delay: -0.144s;
        }

        div:nth-child(4)::after {
            top: 58px;
            left: 32px;
        }

        div:nth-child(5) {
            animation-delay: -0.18s;
        }

        div:nth-child(5)::after {
            top: 57px;
            left: 25px;
        }

        div:nth-child(6) {
            animation-delay: -0.216s;
        }

        div:nth-child(6)::after {
            top: 54px;
            left: 19px;
        }

        div:nth-child(7) {
            animation-delay: -0.252s;
        }

        div:nth-child(7)::after {
            top: 50px;
            left: 14px;
        }

        div:nth-child(8) {
            animation-delay: -0.288s;
        }

        div:nth-child(8)::after {
            top: 45px;
            left: 10px;
        }
    }

    .header {
        position: absolute;
        z-index: 100;
        left: 280px;
    }

    .main {
        display: flex;
        height: calc(100% - 300px);

        .player {
            flex: 1;
        }

        .subtitles {
            width: 300px;
        }

        .tool {
            width: 300px;
        }
    }

    .footer {
        height: 300px;
    }
`;

export default function App({ defaultLang }) {
    const subtitleHistory = useRef([]);
    const notificationSystem = useRef(null);
    const [inputData, setInputData] = useState({});
    const [error, setError] = useState(false);
    const [audio, setAudio] = useState(null);
    const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState('');
    const [processing, setProcessing] = useState(0);
    const [language, setLanguage] = useState(defaultLang);
    const [subtitle, setSubtitleOriginal] = useState([]);
    const [waveform, setWaveform] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(-1);

    const [hasChangedOnLyrics, setHasChangedOnLyrics] = useState(false);

    const newSub = useCallback((item) => new Sub(item), []);
    const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle]);

    useEffect(() => {
        const paths = window.location.pathname.split('/');
        const base64Data = paths[1];
        const decodedData = Buffer.from(base64Data, 'base64');
        const data = JSON.parse(decodedData.toString('utf-8'));
        // console.log('data', data);
        setInputData({ ...data });
    }, []);

    const formatSub = useCallback(
        (sub) => {
            if (Array.isArray(sub)) {
                return sub.map((item) => newSub(item));
            }
            return newSub(sub);
        },
        [newSub],
    );

    const copySubs = useCallback(() => formatSub(subtitle), [subtitle, formatSub]);

    const setSubtitle = useCallback(
        (newSubtitle, saveToHistory = true) => {
            if (!isEqual(newSubtitle, subtitle)) {
                setHasChangedOnLyrics(true);
                if (saveToHistory) {
                    if (subtitleHistory.current.length >= 1000) {
                        subtitleHistory.current.shift();
                    }
                    subtitleHistory.current.push(formatSub(subtitle));
                }
                window.localStorage.setItem('subtitle', JSON.stringify(newSubtitle));
                setSubtitleOriginal(newSubtitle);
            }
        },
        [subtitle, setSubtitleOriginal, formatSub],
    );

    const undoSubs = useCallback(() => {
        const subs = subtitleHistory.current.pop();
        if (subs) {
            setSubtitle(subs, false);
        }
    }, [setSubtitle, subtitleHistory]);

    const clearSubs = useCallback(() => {
        setSubtitle([]);
        subtitleHistory.current.length = 0;
    }, [setSubtitle, subtitleHistory]);

    const checkSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const previous = subtitle[index - 1];
            return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.01;
        },
        [subtitle, hasSub],
    );

    const notify = useCallback(
        (obj) => {
            // https://github.com/igorprado/react-notification-system
            const notification = notificationSystem.current;
            notification.clearNotifications();
            notification.addNotification({
                position: 'tc',
                dismissible: 'none',
                autoDismiss: 2,
                message: obj.message,
                level: obj.level,
            });
        },
        [notificationSystem],
    );

    const removeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            subs.splice(index, 1);
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle],
    );

    const addSub = useCallback(
        (index, sub) => {
            const subs = copySubs();
            const subCurrent = sub;
            const subAfter = subs[index] || null;

            // check if end value is bigger than start value of next item
            if (subAfter && formatToSeconds(subCurrent.end) > formatToSeconds(subAfter.start)) {
                subCurrent.end = subAfter.start;
            }

            subs.splice(index, 0, formatSub(sub));
            setSubtitle(subs);
        },
        [copySubs, setSubtitle, formatSub],
    );

    const updateSub = useCallback(
        (sub, modified) => {
            const index = hasSub(sub);
            if (index < 0) return;

            const subs = copySubs();
            const subBefore = subs[index - 1] || null;
            const subAfter = subs[index + 1] || null;

            // check if end value is bigger than start value of next item
            if (subAfter && modified.end && formatToSeconds(modified.end) > formatToSeconds(subAfter.start)) {
                modified.end = subAfter.start;
            }

            // check if start value is smaller than end value of last item
            if (subBefore && modified.start && formatToSeconds(modified.start) < formatToSeconds(subBefore.end)) {
                modified.start = subBefore.end;
            }

            const subClone = formatSub(sub);
            Object.assign(subClone, modified);
            if (subClone.check) {
                subs[index] = subClone;
                setSubtitle(subs);
            }
        },
        [hasSub, copySubs, setSubtitle, formatSub],
    );

    const mergeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const next = subs[index + 1];
            if (!next) return;
            const merge = newSub({
                start: sub.start,
                end: next.end,
                text: sub.text.trim() + '\n' + next.text.trim(),
            });
            subs[index] = merge;
            subs.splice(index + 1, 1);
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle, newSub],
    );

    const splitSub = useCallback(
        (sub, start) => {
            const index = hasSub(sub);
            if (index < 0 || !sub.text || !start) return;
            const subs = copySubs();
            const text1 = sub.text.slice(0, start).trim();
            const text2 = sub.text.slice(start).trim();
            if (!text1 || !text2) return;
            const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(3);
            if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
            subs.splice(index, 1);
            const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
            subs.splice(
                index,
                0,
                newSub({
                    start: sub.start,
                    end: middleTime,
                    text: text1,
                }),
            );
            subs.splice(
                index + 1,
                0,
                newSub({
                    start: middleTime,
                    end: sub.end,
                    text: text2,
                }),
            );
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle, newSub],
    );

    const onKeyDown = useCallback(
        (event) => {
            const keyCode = getKeyCode(event);
            switch (keyCode) {
                case 32:
                    event.preventDefault();
                    if (player) {
                        if (playing) {
                            player.pause();
                        } else {
                            player.play();
                        }
                    }
                    break;
                case 90:
                    event.preventDefault();
                    if (event.metaKey) {
                        undoSubs();
                    }
                    break;
                default:
                    break;
            }
        },
        [player, playing, undoSubs],
    );

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    useMemo(() => {
        const currentIndex = subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime);
        setCurrentIndex(currentIndex);
    }, [currentTime, subtitle]);

    useEffect(() => {
        if (!inputData.audioUrl || !inputData.lyricsUrl || !inputData.userToken) return;
        // const localSubtitles = window.localStorage.getItem('subtitle')
        // const lastUrl = window.localStorage.getItem('lasturl')

        setAudio(inputData.audioUrl);

        const fetchSubtitle = () =>
            fetch(inputData.lyricsUrl, {
                headers: new Headers({
                    Authorization: inputData.userToken,
                }),
            })
                .then((res) => res.json())
                .then((res) => {
                    // console.log('fetch lyrics', res);
                    if (res.error) {
                        setError(true);
                        return;
                    }

                    const result = res.modified && res.modified.length > 0 ? res.modified : res.fileContent;
                    const content = result.filter((i) => i.text !== '<EOL>');
                    let newLine = false;

                    const lyrics = content
                        .map((item, index) => {
                            if (item.text === '<SOL>') {
                                newLine = true;
                            } else if (newLine) {
                                newLine = false;
                            }

                            // verifica se o end time do item atual
                            // é menor que o start da próxima palavra
                            const nextItem = content[index + 1] || null;
                            const end =
                                nextItem && nextItem.start < item.end
                                    ? formatTimeToTimeline(nextItem.start)
                                    : formatTimeToTimeline(item.end);

                            const newItem = {
                                start: formatTimeToTimeline(item.start),
                                end,
                                text: item.text,
                                originalData: item,
                                newLine,
                            };

                            return newItem;
                        })
                        .filter((i) => !(i.text === '<EOL>' || (inputData.lockPremium && i.originalData.start >= 60)));
                    // .filter((i) => i.text !== '<SOL>' && i.text !== '<EOL>');

                    // para free users:
                    // adiciona referência pra exibir botão 'get premium'
                    if (inputData.lockPremium && lyrics.length) {
                        const lastItem = lyrics[lyrics.length - 1];
                        const lockItem = {
                            start: formatTimeToTimeline(lastItem.originalData.end + 0.2),
                            end: formatTimeToTimeline(lastItem.originalData.end + 0.5),
                            text: 'lock-premium-item',
                            originalData: {},
                            newLine: false,
                        };
                        lyrics.push(lockItem);
                    }

                    setSubtitleOriginal(lyrics.map((item) => new Sub(item)));
                });

        fetchSubtitle();

        // if (localSubtitles && lastUrl === inputData.subtitleUrl) {
        //         try {
        //             const localSubtitle = JSON.parse(localSubtitles.subtitleUrl);
        //             if (localSubtitle.length) {
        //                 window.localStorage.setItem('lasturl', inputData.subtitleUrl)
        //                 setSubtitleOriginal(localSubtitle.map((item) => new Sub(item)));
        //             } else {
        //                 window.localStorage.setItem('lasturl', inputData.subtitleUrl)
        //                 fetchSubtitle();
        //             }
        //         } catch (error) {
        //             window.localStorage.setItem('lasturl', inputData.subtitleUrl)
        //             fetchSubtitle();
        //         }
        // } else {
        //     window.localStorage.setItem('lasturl', inputData.subtitleUrl)
        //     fetchSubtitle();
        // }
    }, [setSubtitleOriginal, inputData]);

    const props = {
        player,
        setPlayer,
        subtitle,
        setSubtitle,
        waveform,
        setWaveform,
        currentTime,
        setCurrentTime,
        currentIndex,
        setCurrentIndex,
        playing,
        setPlaying,
        language,
        setLanguage,
        loading,
        setLoading,
        setProcessing,
        subtitleHistory,
        hasChangedOnLyrics,
        lockPremium: inputData.lockPremium,

        notify,
        newSub,
        hasSub,
        checkSub,
        removeSub,
        addSub,
        undoSubs,
        clearSubs,
        updateSub,
        formatSub,
        mergeSub,
        splitSub,
    };

    if (error) {
        return (
            <Style>
                <div className="error_template">
                    <img src="icons/ic-no-lyrics.svg" width={80} alt="file" />
                    <p className="error_template_title">Sorry, we could not load the lyrics for this song.</p>
                    <p className="error_template_description">
                        Please try again. If the problem persists, contact our support team.
                    </p>
                </div>
            </Style>
        );
    }

    if (!inputData.audioUrl || !inputData.lyricsUrl || !audio) {
        return (
            <Style>
                <div className="loading_default">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </Style>
        );
    }

    return (
        <Style>
            <div className="main">
                {/* <div className="header"><h1>Lyrics Editor</h1></div> */}
                <Subtitles {...props} />
                <Player {...props} src={audio} />
                <Tool {...props} />
            </div>
            <Footer {...props} src={audio} />
            {loading ? <Loading loading={loading} /> : null}
            {processing > 0 && processing < 100 ? <ProgressBar processing={processing} /> : null}
            <NotificationSystem ref={notificationSystem} allowHTML={true} />
        </Style>
    );
}
